/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface WorkspaceResponseInfo {
  id: string;
  responseClientId?: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface CreateCircuitDto {
  workspaceResponseId: string;
  workspaceResponseInfo: WorkspaceResponseInfo;
}

export interface AddDocument {
  orderRequestId: string;
  externalOrderRequestId: string;
  workspaceResponseId: string;
}

export interface SignatureRequest {
  workspaceResponseId?: string;
  clientId?: string;
  token?: string;
}

export interface BulkSignature {
  listworkspaceResponseId?: string[];
}

export type StreamableFile = object;

export interface ReferenceInfo {
  reference: string;
}

export interface WorkspaceGroup {
  id: string;
  updatedAt?: object;
  workspace?: Workspace;
  name: string;
  /** @format date-time */
  createdAt: string;
  deletedAt?: object;
  reachedPage?: number;
  rang?: number;
}

export interface WorkspaceResponsesOnClient {
  id: string;
  /** @format date-time */
  createdAt: string;
  updatedAt?: object;
  deletedAt?: object;
  client?: Client;
  workspaceResponse?: WorkspaceResponse;
}

export interface Client {
  id: string;
  updatedAt?: object;
  isBanned?: boolean;
  firstName?: string;
  lastName?: string;
  password?: string;
  workspaceResponsesOnClients?: WorkspaceResponsesOnClient[];
  /** @format date-time */
  createdAt: string;
  deletedAt?: object;
  email: string;
  workspace: Workspace;
}

export interface User {
  isBanned?: boolean;
  id: string;
  /** @format date-time */
  createdAt: string;
  updatedAt?: object;
  deletedAt?: object;
  firstName?: string;
  lastName?: string;
  username: string;
  roles: string[];
  workspaces?: Workspace[];
}

export interface Workspace {
  id: string;
  /** @format date-time */
  createdAt: string;
  updatedAt?: object;
  deletedAt?: object;
  workspaceSteps?: WorkspaceStep[];
  workspaceGroups?: WorkspaceGroup[];
  coverImg?: string;
  nameLink?: string;
  title?: string;
  description?: string;
  emailSender?: string;
  contract: object;
  clients?: Client[];
  workspaceVersions?: WorkspaceVersion[];
  status?: "Published" | "Waiting" | "Closed" | "Draft";
  user?: User;
  signingType?: "OneSignature" | "TwoSignature" | "WithoutSignature";
}

export interface WorkspaceStep {
  /** @format date-time */
  createdAt: string;
  deletedAt?: object;
  workspace?: Workspace;
  id: string;
  updatedAt?: object;
  workspaceResponses?: WorkspaceResponse[];
  type?: "Prospect" | "File";
  name: string;
  rang?: number;
}

export interface Activity {
  /** @format date-time */
  createdAt: string;
  deletedAt?: object;
  id: string;
  updatedAt?: object;
  workspaceResponse?: WorkspaceResponse;
  data: string;
}

export interface WorkspaceResponse {
  signingStatus?: "Step0" | "Step1" | "Step2" | "Step3";
  /** @format date-time */
  createdAt: string;
  deletedAt?: object;
  workspaceVersion?: WorkspaceVersion;
  id: string;
  updatedAt?: object;
  workspaceStep?: WorkspaceStep;
  activities?: Activity[];
  data?: object;
  order?: object;
  document?: string[];
  type?: "Prospect" | "File";
  workspaceResponsesOnClients?: WorkspaceResponsesOnClient[];
  reachedPage?: number;
  workspaceGroup?: WorkspaceGroup;
  isNotified?: boolean;
  isSignedByAdmin?: boolean;
}

export interface WorkspaceVersion {
  id: string;
  updatedAt?: object;
  active?: boolean;
  workspaceResponses?: WorkspaceResponse[];
  data: object;
  name: string;
  /** @format date-time */
  createdAt: string;
  deletedAt?: object;
  workspace?: Workspace;
}

export interface ForbiddenException {
  statusCode: number;
  message: string;
}

export interface NotFoundException {
  statusCode: number;
  message: string;
}

export interface WorkspaceResponseCreateDto {
  workspaceId: string;
  clientId: string;
}

export interface ListWorkspaceResponseIdDto {
  workspaceResponseIds: string[];
  deleteValue: boolean;
  date?: object;
}

export interface WorkspaceWhereUniqueInput {
  id: string;
}

export interface WorkspaceVersionWhereUniqueInput {
  id: string;
  workspace?: WorkspaceWhereUniqueInput;
}

export interface WorkspaceStepWhereUniqueInput {
  id: string;
  type?: "Prospect" | "File";
}

export interface WorkspaceGroupWhereUniqueInput {
  id: string;
}

export interface WorkspaceResponseCreateInput {
  signingStatus?: "Step0" | "Step1" | "Step2" | "Step3";
  isSignedByAdmin?: boolean;
  deletedAt?: object;
  workspaceVersion?: WorkspaceVersionWhereUniqueInput;
  workspaceStep?: WorkspaceStepWhereUniqueInput;
  data?: object;
  order?: object;
  document?: string[];
  type?: "Prospect" | "File";
  reachedPage?: number;
  isDeleted?: boolean;
  isNotified?: boolean;
  workspaceGroup?: WorkspaceGroupWhereUniqueInput;
}

export interface StringFilter {
  equals?: string;
  in?: string[];
  notIn?: string[];
  lt?: string;
  lte?: string;
  gt?: string;
  gte?: string;
  contains?: string;
  startsWith?: string;
  endsWith?: string;
  mode?: "Default" | "Insensitive";
  not?: string;
}

export interface JsonFilter {
  equals?: any;
  not?: any;
}

export interface BooleanNullableFilter {
  equals?: boolean;
  not?: boolean;
}

export interface StringNullableFilter {
  equals?: string;
  in?: string[];
  notIn?: string[];
  lt?: string;
  lte?: string;
  gt?: string;
  gte?: string;
  contains?: string;
  startsWith?: string;
  endsWith?: string;
  mode?: "Default" | "Insensitive";
  not?: string;
}

export interface ClientWhereInput {
  id?: StringFilter;
  isBanned?: BooleanNullableFilter;
  firstName?: StringNullableFilter;
  lastName?: StringNullableFilter;
  email?: StringFilter;
  workspace?: WorkspaceWhereUniqueInput;
}

export interface WorkspaceResponseWhereUniqueInput {
  id: string;
}

export interface WorkspaceResponsesOnClientWhereInputMailDto {
  id?: StringFilter;
  client?: ClientWhereInput;
  workspaceResponse?: WorkspaceResponseWhereUniqueInput;
}

export interface WorkspaceResponsesOnClientListRelationFilter {
  none?: WorkspaceResponsesOnClientWhereInputMailDto;
  some?: WorkspaceResponsesOnClientWhereInputMailDto;
  every?: WorkspaceResponsesOnClientWhereInputMailDto;
}

export interface WorkspaceResponseWhereInput {
  signingStatus?: "Step0" | "Step1" | "Step2" | "Step3";
  workspaceVersion?: WorkspaceVersionWhereUniqueInput;
  id?: StringFilter;
  workspaceStep?: WorkspaceStepWhereUniqueInput;
  workspace?: WorkspaceWhereUniqueInput;
  data?: JsonFilter;
  type?: "Prospect" | "File";
  workspaceResponsesOnClients?: WorkspaceResponsesOnClientListRelationFilter;
  isDeleted?: boolean;
  isNotified?: boolean;
  isSignedByAdmin?: boolean;
  workspaceGroup?: WorkspaceGroupWhereUniqueInput;
}

export interface WorkspaceResponseOrderByInput {
  createdAt?: "Asc" | "Desc";
  signingStatus?: "Asc" | "Desc";
  deletedAt?: "Asc" | "Desc";
  workspaceVersionId?: "Asc" | "Desc";
  id?: "Asc" | "Desc";
  updatedAt?: "Asc" | "Desc";
  workspaceStepId?: "Asc" | "Desc";
  data?: "Asc" | "Desc";
  type?: "Asc" | "Desc";
}

export interface GetListWorkspaceResponseDto {
  paginatedResult: WorkspaceResponse[];
  totalCount: number;
}

export interface WorkspaceResponseUpdateInputWithPageDto {
  signingStatus?: "Step0" | "Step1" | "Step2" | "Step3";
  activityName?: string;
  deletedAt?: object;
  isSignedByAdmin?: boolean;
  workspaceVersion?: WorkspaceVersionWhereUniqueInput;
  workspaceStep?: WorkspaceStepWhereUniqueInput;
  data?: object;
  order?: object;
  document?: string[];
  type?: "Prospect" | "File";
  reachedPage?: number;
  workspaceGroup?: WorkspaceGroupWhereUniqueInput;
}

export interface UserWhereUniqueInput {
  id: string;
}

export interface ClientWhereUniqueInput {
  id: string;
}

export interface FilterWorkspaceResponseInput {
  workspaceId: string;
  response: string;
  take?: string;
  skip?: string;
}

export interface InviteClientDto {
  email: string;
  workspaceId: string;
  workspaceResponseId: string;
}

export interface Mailing {
  emailes?: string[];
}

export interface FilterWorkspaceResponse {
  workspaceId: string;
  type?: "Prospect" | "File";
  groupId?: string;
  email?: string;
}

export interface Credentials {
  username: string;
  password: string;
}

export interface DtoClientLogin {
  email: string;
  password: string;
}

export interface SignUpCredentials {
  username: string;
  firstName: string;
  lastName: string;
  password: string;
  role: string;
}

export interface ClientSignUpCredentials {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}

export interface EmailResetPasswordCredential {
  username: string;
}

export interface ClientEmailResetPasswordCredential {
  email: string;
}

export interface ResetPasswordCredential {
  newPassword: string;
  token: string;
}

export interface TokenCredential {
  token: string;
}

export interface BadRequestException {
  statusCode: number;
  message: string;
}

export interface UnauthorizedException {
  statusCode: number;
  message: string;
}

export interface UpdateClientDto {
  firstName?: string;
  lastName?: string;
  password: string;
  token: string;
}

export interface UserUpdateInputFromAdminDto {
  id: string;
  isBanned?: boolean;
  deletedAt?: object;
  firstName?: string;
  lastName?: string;
  username?: string;
  password?: string;
  roles?: string[];
}

export interface UserCreateInput {
  isBanned?: boolean;
  deletedAt?: object;
  firstName?: string;
  lastName?: string;
  username: string;
  password: string;
  roles: string[];
}

export interface SendEmailByGroupDto {
  workspaceResponses: string[];
  templateId: string;
}

export interface UserWhereInput {
  id?: StringFilter;
  firstName?: StringNullableFilter;
  lastName?: StringNullableFilter;
  username?: StringFilter;
  email?: StringFilter;
}

export interface UserOrderByInput {
  id?: "Asc" | "Desc";
  createdAt?: "Asc" | "Desc";
  updatedAt?: "Asc" | "Desc";
  deletedAt?: "Asc" | "Desc";
  firstName?: "Asc" | "Desc";
  lastName?: "Asc" | "Desc";
  username?: "Asc" | "Desc";
  password?: "Asc" | "Desc";
  roles?: "Asc" | "Desc";
}

export interface GetListUserDto {
  paginatedResult: User[];
  totalCount: number;
}

export interface UserUpdateInput {
  isBanned?: boolean;
  deletedAt?: object;
  firstName?: string;
  lastName?: string;
  username?: string;
  password?: string;
  roles?: string[];
}

export type UpdatePasswordDTO = object;

export interface File {
  originalname?: string;
  encoding?: string;
  mimetype?: string;
  size?: number;
  filename?: string;
  path?: string;
}

export interface DownloadFile {
  path: string;
}

export interface Doc {
  files: string[];
}

export interface AppConfigCreateInput {
  deletedAt?: object;
  value?: string;
  key?: string;
}

export interface AppConfig {
  id: string;
  /** @format date-time */
  createdAt: string;
  updatedAt?: object;
  deletedAt?: object;
  value?: string;
  key?: string;
}

export interface AppConfigWhereInput {
  id?: StringFilter;
  value?: StringNullableFilter;
  key?: StringNullableFilter;
}

export interface AppConfigOrderByInput {
  id?: "Asc" | "Desc";
  createdAt?: "Asc" | "Desc";
  updatedAt?: "Asc" | "Desc";
  deletedAt?: "Asc" | "Desc";
  value?: "Asc" | "Desc";
  key?: "Asc" | "Desc";
}

export interface GetListAppConfigDto {
  paginatedResult: AppConfig[];
  totalCount: number;
}

export interface AppConfigUpdateInput {
  deletedAt?: object;
  value?: string;
  key?: string;
}

export interface NotificationCreateInput {
  deletedAt?: object;
  content?: object;
  client?: ClientWhereUniqueInput;
  isSee?: boolean;
}

export interface Notification {
  id: string;
  /** @format date-time */
  createdAt: string;
  updatedAt?: object;
  deletedAt?: object;
  content?: object;
  client?: Client;
  isSee?: boolean;
}

export interface NotificationWhereInput {
  id?: StringFilter;
  content?: JsonFilter;
  client?: ClientWhereUniqueInput;
  isSee?: BooleanNullableFilter;
}

export interface NotificationOrderByInput {
  id?: "Asc" | "Desc";
  createdAt?: "Asc" | "Desc";
  updatedAt?: "Asc" | "Desc";
  deletedAt?: "Asc" | "Desc";
  content?: "Asc" | "Desc";
  clientId?: "Asc" | "Desc";
  isSee?: "Asc" | "Desc";
}

export interface GetListNotificationDto {
  paginatedResult: Notification[];
  totalCount: number;
}

export interface NotificationUpdateInput {
  deletedAt?: object;
  content?: object;
  client?: ClientWhereUniqueInput;
  isSee?: boolean;
}

export interface WorkspaceVersionCreateInput {
  active?: boolean;
  data: object;
  name: string;
  deletedAt?: object;
  workspace?: WorkspaceWhereUniqueInput;
}

export interface WorkspaceCreateWithVersionDto {
  deletedAt?: object;
  coverImg?: string;
  nameLink?: string;
  title?: string;
  description?: string;
  emailSender?: string;
  contract: object;
  status?: "Published" | "Waiting" | "Closed" | "Draft";
  user?: UserWhereUniqueInput;
  workspaceVersions?: WorkspaceVersionCreateInput;
  signingType?: "OneSignature" | "TwoSignature" | "WithoutSignature";
}

export interface WorkspaceWhereInput {
  id?: StringFilter;
  coverImg?: StringNullableFilter;
  nameLink?: StringNullableFilter;
  title?: StringNullableFilter;
  description?: StringNullableFilter;
  emailSender?: StringNullableFilter;
  contract?: StringNullableFilter;
  status?: "Published" | "Waiting" | "Closed" | "Draft";
  user?: UserWhereUniqueInput;
  signingType?: "OneSignature" | "TwoSignature" | "WithoutSignature";
}

export interface WorkspaceOrderByInput {
  id?: "Asc" | "Desc";
  createdAt?: "Asc" | "Desc";
  updatedAt?: "Asc" | "Desc";
  deletedAt?: "Asc" | "Desc";
  coverImg?: "Asc" | "Desc";
  nameLink?: "Asc" | "Desc";
  title?: "Asc" | "Desc";
  description?: "Asc" | "Desc";
  emailSender?: "Asc" | "Desc";
  contract?: "Asc" | "Desc";
  status?: "Asc" | "Desc";
  userId?: "Asc" | "Desc";
}

export interface GetListWorkspaceDto {
  paginatedResult: Workspace[];
  totalCount: number;
}

export interface UpdateWorkspaceInfoDto {
  id: string;
  deletedAt?: object;
  coverImg?: string;
  nameLink?: string;
  title?: string;
  description?: string;
  emailSender?: string;
  contract: object;
  status?: "Published" | "Waiting" | "Closed" | "Draft";
  user?: UserWhereUniqueInput;
  signingType?: "OneSignature" | "TwoSignature" | "WithoutSignature";
}

export interface ClientCreateInput {
  isBanned?: boolean;
  firstName?: string;
  lastName?: string;
  password: string;
  deletedAt?: object;
  email: string;
  workspace: WorkspaceWhereUniqueInput;
}

export interface ClientOrderByInput {
  id?: "Asc" | "Desc";
  updatedAt?: "Asc" | "Desc";
  isBanned?: "Asc" | "Desc";
  firstName?: "Asc" | "Desc";
  lastName?: "Asc" | "Desc";
  password?: "Asc" | "Desc";
  createdAt?: "Asc" | "Desc";
  deletedAt?: "Asc" | "Desc";
  email?: "Asc" | "Desc";
  workspaceId?: "Asc" | "Desc";
}

export interface GetListClientDto {
  paginatedResult: Client[];
  totalCount: number;
}

export interface ClientUpdateInput {
  isBanned?: boolean;
  firstName?: string;
  lastName?: string;
  password?: string;
  deletedAt?: object;
  email?: string;
  workspace?: WorkspaceWhereUniqueInput;
}

export interface WorkspaceStepCreateInput {
  deletedAt?: object;
  workspace?: WorkspaceWhereUniqueInput;
  type?: "Prospect" | "File";
  name: string;
  rang?: number;
}

export interface FetchStepDto {
  email?: string;
  groupId?: string;
}

export interface WorkspaceStepWhereInput {
  workspace?: WorkspaceWhereUniqueInput;
  id?: StringFilter;
  type?: "Prospect" | "File";
  name?: StringFilter;
}

export interface WorkspaceStepOrderByInput {
  createdAt?: "Asc" | "Desc";
  deletedAt?: "Asc" | "Desc";
  workspaceId?: "Asc" | "Desc";
  id?: "Asc" | "Desc";
  updatedAt?: "Asc" | "Desc";
  type?: "Asc" | "Desc";
  name?: "Asc" | "Desc";
  rang?: "Asc" | "Desc";
}

export interface GetListWorkspaceStepDto {
  paginatedResult: WorkspaceStep[];
  totalCount: number;
}

export interface WorkspaceStepUpdateInput {
  deletedAt?: object;
  workspace?: WorkspaceWhereUniqueInput;
  type?: "Prospect" | "File";
  name?: string;
  rang?: number;
}

export interface WorkspaceVersionWhereInput {
  id?: StringFilter;
  active?: BooleanNullableFilter;
  data?: JsonFilter;
  name?: StringFilter;
  workspace?: WorkspaceWhereUniqueInput;
}

export interface WorkspaceVersionOrderByInput {
  id?: "Asc" | "Desc";
  updatedAt?: "Asc" | "Desc";
  active?: "Asc" | "Desc";
  data?: "Asc" | "Desc";
  name?: "Asc" | "Desc";
  createdAt?: "Asc" | "Desc";
  deletedAt?: "Asc" | "Desc";
  workspaceId?: "Asc" | "Desc";
}

export interface GetListWorkspaceVersionDto {
  paginatedResult: WorkspaceVersion[];
  totalCount: number;
}

export interface WorkspaceVersionUpdateInput {
  active?: boolean;
  data?: object;
  name?: string;
  deletedAt?: object;
  workspace?: WorkspaceWhereUniqueInput;
}

export interface ExportExcelWorkspaceResponseInput {
  workspaceId: string;
  type?: "Prospect" | "File";
  groupName?: string;
}

export interface ImportExcelWorkspaceResponseInput {
  workspaceId: string;
  type?: "Prospect" | "File";
  data?: string[];
  groupId?: string;
  clientEmail?: string;
}

export interface InvitAllClient {
  clients?: string[];
  workspaceId?: string;
}

export interface WorkspaceGroupWhereInput {
  id?: StringFilter;
  workspace?: WorkspaceWhereUniqueInput;
  name?: StringFilter;
}

export interface WorkspaceGroupOrderByInput {
  id?: "Asc" | "Desc";
  updatedAt?: "Asc" | "Desc";
  workspaceId?: "Asc" | "Desc";
  name?: "Asc" | "Desc";
  createdAt?: "Asc" | "Desc";
  rang?: "Asc" | "Desc";
  deletedAt?: "Asc" | "Desc";
}

export interface GetListWorkspaceGroupDto {
  paginatedResult: WorkspaceGroup[];
  totalCount: number;
}

export interface WorkspaceGroupCreateInput {
  workspace?: WorkspaceWhereUniqueInput;
  name: string;
  rang?: number;
  deletedAt?: object;
}

export interface WorkspaceGroupUpdateInput {
  workspace?: WorkspaceWhereUniqueInput;
  name?: string;
  deletedAt?: object;
  rang?: number;
}

export interface ActivityCreateInput {
  deletedAt?: object;
  workspaceResponse?: WorkspaceResponseWhereUniqueInput;
  user?: UserWhereUniqueInput;
  client?: ClientWhereUniqueInput;
  data: string;
}

export interface ActivityWhereInput {
  id?: StringFilter;
  workspaceResponse?: WorkspaceResponseWhereUniqueInput;
  user?: UserWhereUniqueInput;
  client?: ClientWhereUniqueInput;
  data?: StringFilter;
}

export interface ActivityOrderByInput {
  createdAt?: "Asc" | "Desc";
  deletedAt?: "Asc" | "Desc";
  id?: "Asc" | "Desc";
  updatedAt?: "Asc" | "Desc";
  workspaceResponseId?: "Asc" | "Desc";
  data?: "Asc" | "Desc";
}

export interface GetListActivityDto {
  paginatedResult: Activity[];
  totalCount: number;
}

export interface ActivityUpdateInput {
  deletedAt?: object;
  workspaceResponse?: WorkspaceResponseWhereUniqueInput;
  data?: string;
}

export interface WorkspaceResponsesOnClientWhereUniqueInput {
  id: string;
}

export interface WorkspaceResponsesOnClientCreateInput {
  deletedAt?: object;
  client?: ClientWhereUniqueInput;
  workspaceResponse?: WorkspaceResponseWhereUniqueInput;
}

export interface WorkspaceResponsesOnClientWhereInput {
  id?: StringFilter;
  client?: ClientWhereUniqueInput;
  workspaceResponse?: WorkspaceResponseWhereUniqueInput;
}

export interface WorkspaceResponsesOnClientOrderByInput {
  id?: "Asc" | "Desc";
  createdAt?: "Asc" | "Desc";
  updatedAt?: "Asc" | "Desc";
  deletedAt?: "Asc" | "Desc";
  clientId?: "Asc" | "Desc";
  workspaceResponseId?: "Asc" | "Desc";
}

export interface GetListWorkspaceResponsesOnClientDto {
  paginatedResult: WorkspaceResponsesOnClient[];
  totalCount: number;
}

export interface WorkspaceResponsesOnClientUpdateInput {
  deletedAt?: object;
  client?: ClientWhereUniqueInput;
  workspaceResponse?: WorkspaceResponseWhereUniqueInput;
}

export interface TemplateCreateInput {
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  sendAt?: string;
  minuts?: string;
  /** @format date-time */
  deletedAt?: string;
  /** @format date-time */
  updatedAt?: string;
  templateName?: string;
  type?: string;
  sendinblueTemplateId?: string;
}

export interface TemplateWhereUniqueInputId {
  id: string;
}

export interface PaginationTemplateDto {
  take?: number;
  skip?: number;
}

export interface FilterByTitle {
  templateName?: string;
}

export interface TemplateWhereUniqueInputTemplateName {
  templateName: string;
}

export interface UpdateTemplateInput {
  /** @format date-time */
  sendAt?: string;
  minuts?: string;
  type?: string;
  sendinblueTemplateId?: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;

  key?: string;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
  handleError?: void;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);
  private handleError?: (() => void | undefined) | undefined;

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  public setBaseApiParams = (data: RequestParams) => {
    this.baseApiParams = this.mergeRequestParams(data);
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any, requestParam: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any, requestParam) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          requestParam?.key || key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;
    const resp = await this.customFetch(
      `${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`,
      {
        ...requestParams,
        headers: {
          ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
          ...(requestParams.headers || {}),
        },
        signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
        body: typeof body === "undefined" || body === null ? null : payloadFormatter(body, requestParams),
      },
    );
    if (cancelToken) {
      this.abortControllers.delete(cancelToken);
    }
    let data: T;
    let error: E;
    try {
      data = await resp[responseFormat || "json"]();
      if (!resp.ok) {
        if (data?.error?.statusCode === 401 || data?.statusCode === 401) this.handleError?.();
        throw { ...resp, error: data };
      } else return { ...resp, data, error };
    } catch (error) {
      if (error?.error?.statusCode) throw error;
      console.log(error);
    }
  };
}

/**
 * @title Bureau brandies DB
 * @version itgdh2ai
 * @contact
 *
 * Bureau brandies DB
 *
 * ## Congratulations! Your application is ready.
 *
 *     Please note that all endpoints are secured with JWT Bearer authentication.
 *     By default, your app comes with one user with the username "admin" and password "admin".
 *     Learn more in [our docs](https://docs.amplication.com)
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags signerEnLigne
     * @name SignerEnLigneControllerCreateCircuit
     * @request POST:/api/signerEnLigne/createCircuit
     */
    signerEnLigneControllerCreateCircuit: (data: CreateCircuitDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/signerEnLigne/createCircuit`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags signerEnLigne
     * @name SignerEnLigneControllerAddDocument
     * @request POST:/api/signerEnLigne/addDocument
     */
    signerEnLigneControllerAddDocument: (data: AddDocument, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/signerEnLigne/addDocument`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags signerEnLigne
     * @name SignerEnLigneControllerCreateOrder
     * @request POST:/api/signerEnLigne/createOrder
     */
    signerEnLigneControllerCreateOrder: (data: CreateCircuitDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/signerEnLigne/createOrder`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags signerEnLigne
     * @name SignerEnLigneControllerCreateEphemeralSignature
     * @request POST:/api/signerEnLigne/createEphemeralSignature
     */
    signerEnLigneControllerCreateEphemeralSignature: (data: CreateCircuitDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/signerEnLigne/createEphemeralSignature`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags signerEnLigne
     * @name SignerEnLigneControllerSignEphemeralSignatureRequest
     * @request POST:/api/signerEnLigne/signEphemeralSignatureRequest
     */
    signerEnLigneControllerSignEphemeralSignatureRequest: (data: SignatureRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/signerEnLigne/signEphemeralSignatureRequest`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags signerEnLigne
     * @name SignerEnLigneControllerBulkSignature
     * @request POST:/api/signerEnLigne/bulkSignature
     */
    signerEnLigneControllerBulkSignature: (data: BulkSignature, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/signerEnLigne/bulkSignature`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags signerEnLigne
     * @name SignerEnLigneControllerSignEphemeralSignatureRequestByAdmin
     * @request POST:/api/signerEnLigne/signEphemeralSignatureRequestByAdmin
     */
    signerEnLigneControllerSignEphemeralSignatureRequestByAdmin: (data: SignatureRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/signerEnLigne/signEphemeralSignatureRequestByAdmin`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags signerEnLigne
     * @name SignerEnLigneControllerGeneratePdf
     * @request GET:/api/signerEnLigne/exportPdf/{id}
     */
    signerEnLigneControllerGeneratePdf: (id: string, params: RequestParams = {}) =>
      this.request<StreamableFile, void>({
        path: `/api/signerEnLigne/exportPdf/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags signerEnLigne
     * @name SignerEnLigneControllerDowloadContratSignerByclient
     * @request GET:/api/signerEnLigne/dowloadContratSignerByclient/{id}
     */
    signerEnLigneControllerDowloadContratSignerByclient: (id: string, params: RequestParams = {}) =>
      this.request<StreamableFile, void>({
        path: `/api/signerEnLigne/dowloadContratSignerByclient/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags signerEnLigne
     * @name SignerEnLigneControllerDownloadContrat
     * @request POST:/api/signerEnLigne/downloadContrat
     */
    signerEnLigneControllerDownloadContrat: (data: ReferenceInfo, params: RequestParams = {}) =>
      this.request<StreamableFile, any>({
        path: `/api/signerEnLigne/downloadContrat`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags signerEnLigne
     * @name SignerEnLigneControllerGetSigningLink
     * @request POST:/api/signerEnLigne/getSigningLink
     */
    signerEnLigneControllerGetSigningLink: (data: ReferenceInfo, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/signerEnLigne/getSigningLink`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses
     * @name WorkspaceResponseControllerDeleteWorkspaceResponses
     * @request PATCH:/api/workspace-responses/deleteWorkspaceResponses/{id}
     * @secure
     */
    workspaceResponseControllerDeleteWorkspaceResponses: (id: string, params: RequestParams = {}) =>
      this.request<WorkspaceResponse, ForbiddenException | NotFoundException>({
        path: `/api/workspace-responses/deleteWorkspaceResponses/${id}`,
        method: "PATCH",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses
     * @name WorkspaceResponseControllerCreateWorkspaceResponsesByWorkspaceId
     * @request POST:/api/workspace-responses/createWorkspaceResponsesByWorkspaceId
     * @secure
     */
    workspaceResponseControllerCreateWorkspaceResponsesByWorkspaceId: (
      data: WorkspaceResponseCreateDto,
      params: RequestParams = {},
    ) =>
      this.request<WorkspaceResponse, ForbiddenException>({
        path: `/api/workspace-responses/createWorkspaceResponsesByWorkspaceId`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses
     * @name WorkspaceResponseControllerUpdateMany
     * @request POST:/api/workspace-responses/updateMany
     * @secure
     */
    workspaceResponseControllerUpdateMany: (data: ListWorkspaceResponseIdDto, params: RequestParams = {}) =>
      this.request<WorkspaceResponse, ForbiddenException>({
        path: `/api/workspace-responses/updateMany`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses
     * @name WorkspaceResponseControllerCreate
     * @request POST:/api/workspace-responses
     * @secure
     */
    workspaceResponseControllerCreate: (data: WorkspaceResponseCreateInput, params: RequestParams = {}) =>
      this.request<WorkspaceResponse, ForbiddenException>({
        path: `/api/workspace-responses`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses
     * @name WorkspaceResponseControllerFindManyByWorkspace
     * @request POST:/api/workspace-responses/WorkspaceResponses
     * @secure
     */
    workspaceResponseControllerFindManyByWorkspace: (
      query?: {
        where?: WorkspaceResponseWhereInput;
        orderBy?: WorkspaceResponseOrderByInput;
        skip?: number;
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetListWorkspaceResponseDto, void>({
        path: `/api/workspace-responses/WorkspaceResponses`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses
     * @name WorkspaceResponseControllerFindOne
     * @request GET:/api/workspace-responses/{id}
     * @secure
     */
    workspaceResponseControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<WorkspaceResponse, ForbiddenException | NotFoundException>({
        path: `/api/workspace-responses/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses
     * @name WorkspaceResponseControllerUpdate
     * @request PATCH:/api/workspace-responses/{id}
     * @secure
     */
    workspaceResponseControllerUpdate: (
      id: string,
      data: WorkspaceResponseUpdateInputWithPageDto,
      params: RequestParams = {},
    ) =>
      this.request<WorkspaceResponse, ForbiddenException | NotFoundException>({
        path: `/api/workspace-responses/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses
     * @name WorkspaceResponseControllerDelete
     * @request DELETE:/api/workspace-responses/{id}
     * @secure
     */
    workspaceResponseControllerDelete: (id: string, params: RequestParams = {}) =>
      this.request<WorkspaceResponse, ForbiddenException | NotFoundException>({
        path: `/api/workspace-responses/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses
     * @name WorkspaceResponseControllerFindManyActivities
     * @request GET:/api/workspace-responses/{id}/activities
     * @secure
     */
    workspaceResponseControllerFindManyActivities: (
      id: string,
      query?: {
        id?: StringFilter;
        workspaceResponse?: WorkspaceResponseWhereUniqueInput;
        user?: UserWhereUniqueInput;
        client?: ClientWhereUniqueInput;
        data?: StringFilter;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/workspace-responses/${id}/activities`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses
     * @name WorkspaceResponseControllerCreateActivities
     * @request POST:/api/workspace-responses/{id}/activities
     * @secure
     */
    workspaceResponseControllerCreateActivities: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/workspace-responses/${id}/activities`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses
     * @name WorkspaceResponseControllerUpdateActivities
     * @request PATCH:/api/workspace-responses/{id}/activities
     * @secure
     */
    workspaceResponseControllerUpdateActivities: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/workspace-responses/${id}/activities`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses
     * @name WorkspaceResponseControllerDeleteActivities
     * @request DELETE:/api/workspace-responses/{id}/activities
     * @secure
     */
    workspaceResponseControllerDeleteActivities: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/workspace-responses/${id}/activities`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses
     * @name WorkspaceResponseControllerFindManyWorkspaceResponsesOnClients
     * @request GET:/api/workspace-responses/{id}/workspaceResponsesOnClients
     * @secure
     */
    workspaceResponseControllerFindManyWorkspaceResponsesOnClients: (
      id: string,
      query?: {
        id?: StringFilter;
        client?: ClientWhereUniqueInput;
        workspaceResponse?: WorkspaceResponseWhereUniqueInput;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/workspace-responses/${id}/workspaceResponsesOnClients`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses
     * @name WorkspaceResponseControllerCreateWorkspaceResponsesOnClients
     * @request POST:/api/workspace-responses/{id}/workspaceResponsesOnClients
     * @secure
     */
    workspaceResponseControllerCreateWorkspaceResponsesOnClients: (
      id: string,
      data: string[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/workspace-responses/${id}/workspaceResponsesOnClients`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses
     * @name WorkspaceResponseControllerUpdateWorkspaceResponsesOnClients
     * @request PATCH:/api/workspace-responses/{id}/workspaceResponsesOnClients
     * @secure
     */
    workspaceResponseControllerUpdateWorkspaceResponsesOnClients: (
      id: string,
      data: string[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/workspace-responses/${id}/workspaceResponsesOnClients`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses
     * @name WorkspaceResponseControllerDeleteWorkspaceResponsesOnClients
     * @request DELETE:/api/workspace-responses/{id}/workspaceResponsesOnClients
     * @secure
     */
    workspaceResponseControllerDeleteWorkspaceResponsesOnClients: (
      id: string,
      data: string[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/workspace-responses/${id}/workspaceResponsesOnClients`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses
     * @name WorkspaceResponseControllerWorkspaceResponses
     * @request POST:/api/workspace-responses/filterWorkspaceResponses
     * @secure
     */
    workspaceResponseControllerWorkspaceResponses: (data: FilterWorkspaceResponseInput, params: RequestParams = {}) =>
      this.request<WorkspaceResponse, ForbiddenException | NotFoundException>({
        path: `/api/workspace-responses/filterWorkspaceResponses`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses
     * @name WorkspaceResponseControllerInviteClient
     * @request POST:/api/workspace-responses/inviteClient
     * @secure
     */
    workspaceResponseControllerInviteClient: (data: InviteClientDto, params: RequestParams = {}) =>
      this.request<any, ForbiddenException>({
        path: `/api/workspace-responses/inviteClient`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses
     * @name WorkspaceResponseControllerUpdateToDir
     * @request PATCH:/api/workspace-responses/updateToDir/{id}/workspaceInfo
     * @secure
     */
    workspaceResponseControllerUpdateToDir: (id: string, params: RequestParams = {}) =>
      this.request<StreamableFile, ForbiddenException | NotFoundException>({
        path: `/api/workspace-responses/updateToDir/${id}/workspaceInfo`,
        method: "PATCH",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses
     * @name WorkspaceResponseControllerSendEmail
     * @request POST:/api/workspace-responses/sendEmail
     * @secure
     */
    workspaceResponseControllerSendEmail: (data: Mailing, params: RequestParams = {}) =>
      this.request<any, ForbiddenException>({
        path: `/api/workspace-responses/sendEmail`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses
     * @name WorkspaceResponseControllerCountWorkspaceResponses
     * @request POST:/api/workspace-responses/countWorkspaceResponses
     * @secure
     */
    workspaceResponseControllerCountWorkspaceResponses: (data: FilterWorkspaceResponse, params: RequestParams = {}) =>
      this.request<any, ForbiddenException>({
        path: `/api/workspace-responses/countWorkspaceResponses`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses
     * @name WorkspaceResponseControllerAddFilesDataToWorkspaceResponse
     * @request POST:/api/workspace-responses/AddFilesDataToWorkspaceResponse
     * @secure
     */
    workspaceResponseControllerAddFilesDataToWorkspaceResponse: (params: RequestParams = {}) =>
      this.request<any, ForbiddenException>({
        path: `/api/workspace-responses/AddFilesDataToWorkspaceResponse`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses
     * @name WorkspaceResponseControllerAddOldWorkspaceResponse
     * @request POST:/api/workspace-responses/AddOldData
     * @secure
     */
    workspaceResponseControllerAddOldWorkspaceResponse: (params: RequestParams = {}) =>
      this.request<any, ForbiddenException>({
        path: `/api/workspace-responses/AddOldData`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses
     * @name WorkspaceResponseControllerUpdateDocumentOldData
     * @request POST:/api/workspace-responses/updateDocumentData
     * @secure
     */
    workspaceResponseControllerUpdateDocumentOldData: (params: RequestParams = {}) =>
      this.request<any, ForbiddenException>({
        path: `/api/workspace-responses/updateDocumentData`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerLogin
     * @request POST:/api/login
     */
    authControllerLogin: (data: Credentials, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerClientLogin
     * @request POST:/api/client_login
     */
    authControllerClientLogin: (data: DtoClientLogin, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/client_login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerSignup
     * @request POST:/api/sign_up
     */
    authControllerSignup: (data: SignUpCredentials, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/sign_up`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerClientSignup
     * @request POST:/api/client_sign_up
     */
    authControllerClientSignup: (data: ClientSignUpCredentials, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/client_sign_up`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerResetPasswordWithEmail
     * @request POST:/api/reset_password_with_email
     */
    authControllerResetPasswordWithEmail: (data: EmailResetPasswordCredential, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/reset_password_with_email`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerClientResetPasswordWithEmail
     * @request POST:/api/client_reset_password_with_email
     */
    authControllerClientResetPasswordWithEmail: (
      data: ClientEmailResetPasswordCredential,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/client_reset_password_with_email`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerResetPassword
     * @request POST:/api/reset_password
     */
    authControllerResetPassword: (data: ResetPasswordCredential, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/reset_password`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerClientResetPassword
     * @request POST:/api/client_reset_password
     */
    authControllerClientResetPassword: (data: ResetPasswordCredential, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/client_reset_password`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerGetUserByToken
     * @request POST:/api/get_user_by_token
     */
    authControllerGetUserByToken: (data: TokenCredential, params: RequestParams = {}) =>
      this.request<any, BadRequestException | UnauthorizedException>({
        path: `/api/get_user_by_token`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerGetClientByToken
     * @request POST:/api/get_client_by_token
     */
    authControllerGetClientByToken: (data: TokenCredential, params: RequestParams = {}) =>
      this.request<any, BadRequestException | UnauthorizedException>({
        path: `/api/get_client_by_token`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerUpdateClientByToken
     * @request POST:/api/updateClientByToken
     */
    authControllerUpdateClientByToken: (data: UpdateClientDto, params: RequestParams = {}) =>
      this.request<any, BadRequestException | UnauthorizedException>({
        path: `/api/updateClientByToken`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerGetUserFromToken
     * @request GET:/api/get_user_from_token/{token}
     */
    authControllerGetUserFromToken: (token: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/get_user_from_token/${token}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerUpdateUser
     * @request PATCH:/api/users
     * @secure
     */
    userControllerUpdateUser: (data: UserUpdateInputFromAdminDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/users`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerCreate
     * @request POST:/api/users
     * @secure
     */
    userControllerCreate: (data: UserCreateInput, params: RequestParams = {}) =>
      this.request<User, ForbiddenException>({
        path: `/api/users`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerFindMany
     * @request GET:/api/users
     * @secure
     */
    userControllerFindMany: (
      query?: {
        where?: UserWhereInput;
        orderBy?: UserOrderByInput;
        skip?: number;
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetListUserDto, void>({
        path: `/api/users`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerInviteUser
     * @request POST:/api/users/invitationUser
     * @secure
     */
    userControllerInviteUser: (data: UserCreateInput, params: RequestParams = {}) =>
      this.request<User, ForbiddenException>({
        path: `/api/users/invitationUser`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerSendEmailByGroup
     * @request POST:/api/users/sendEmailByGroup
     * @secure
     */
    userControllerSendEmailByGroup: (data: SendEmailByGroupDto, params: RequestParams = {}) =>
      this.request<User, ForbiddenException>({
        path: `/api/users/sendEmailByGroup`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerFindOne
     * @request GET:/api/users/{id}
     * @secure
     */
    userControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<User, ForbiddenException | NotFoundException>({
        path: `/api/users/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerDelete
     * @request DELETE:/api/users/{id}
     * @secure
     */
    userControllerDelete: (id: string, params: RequestParams = {}) =>
      this.request<User, ForbiddenException | NotFoundException>({
        path: `/api/users/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerUpdateUserInfo
     * @request PATCH:/api/users/edit/{id}
     * @secure
     */
    userControllerUpdateUserInfo: (id: string, data: UserUpdateInput, params: RequestParams = {}) =>
      this.request<User, ForbiddenException | NotFoundException>({
        path: `/api/users/edit/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerUpdate
     * @request PATCH:/api/users/{token}
     * @secure
     */
    userControllerUpdate: (token: string, data: UserUpdateInput, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/users/${token}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerFindManyWorkspaces
     * @request GET:/api/users/{id}/workspaces
     * @secure
     */
    userControllerFindManyWorkspaces: (
      id: string,
      query?: {
        id?: StringFilter;
        coverImg?: StringNullableFilter;
        nameLink?: StringNullableFilter;
        title?: StringNullableFilter;
        description?: StringNullableFilter;
        emailSender?: StringNullableFilter;
        contract?: StringNullableFilter;
        status?: "Published" | "Waiting" | "Closed" | "Draft";
        user?: UserWhereUniqueInput;
        signingType?: "OneSignature" | "TwoSignature" | "WithoutSignature";
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/users/${id}/workspaces`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerCreateWorkspaces
     * @request POST:/api/users/{id}/workspaces
     * @secure
     */
    userControllerCreateWorkspaces: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/users/${id}/workspaces`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerUpdateWorkspaces
     * @request PATCH:/api/users/{id}/workspaces
     * @secure
     */
    userControllerUpdateWorkspaces: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/users/${id}/workspaces`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerDeleteWorkspaces
     * @request DELETE:/api/users/{id}/workspaces
     * @secure
     */
    userControllerDeleteWorkspaces: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/users/${id}/workspaces`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerUpdatePassword
     * @request PATCH:/api/users/{id}/resetPassword
     * @secure
     */
    userControllerUpdatePassword: (id: string, data: UpdatePasswordDTO, params: RequestParams = {}) =>
      this.request<User, ForbiddenException | NotFoundException>({
        path: `/api/users/${id}/resetPassword`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags file-upload
     * @name FileControllerUploadFile
     * @request POST:/api/file/uploadFile/{id}
     * @secure
     */
    fileControllerUploadFile: (
      id: string,
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, ForbiddenException>({
        path: `/api/file/uploadFile/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags file-upload
     * @name FileControllerUploadFileWithoutId
     * @request POST:/api/file/uploadFile
     * @secure
     */
    fileControllerUploadFileWithoutId: (
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, ForbiddenException>({
        path: `/api/file/uploadFile`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags file-upload
     * @name FileControllerUploadManyFiles
     * @request POST:/api/file/uploadManyFiles/{id}
     * @secure
     */
    fileControllerUploadManyFiles: (
      id: string,
      data: {
        files?: File[];
      },
      params: RequestParams = {},
    ) =>
      this.request<File, ForbiddenException>({
        path: `/api/file/uploadManyFiles/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags file-upload
     * @name FileControllerGetAllFiles
     * @request GET:/api/file/idResponse/{id}
     * @secure
     */
    fileControllerGetAllFiles: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/file/idResponse/${id}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags file-upload
     * @name FileControllerDownloadFile
     * @request POST:/api/file/fileLink
     * @secure
     */
    fileControllerDownloadFile: (data: DownloadFile, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/file/fileLink`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags file-upload
     * @name FileControllerExportManyDocuments
     * @request POST:/api/file/getAllDocuments
     * @secure
     */
    fileControllerExportManyDocuments: (data: Doc, params: RequestParams = {}) =>
      this.request<StreamableFile, void>({
        path: `/api/file/getAllDocuments`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags file-upload
     * @name FileControllerHbs
     * @request GET:/api/file/hbs
     * @secure
     */
    fileControllerHbs: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/file/hbs`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags file-upload
     * @name FileControllerUploadManyFilesWithoutId
     * @request POST:/api/file/uploadManyFilesWithoutId
     * @secure
     */
    fileControllerUploadManyFilesWithoutId: (
      data: {
        files?: File[];
      },
      params: RequestParams = {},
    ) =>
      this.request<File, ForbiddenException>({
        path: `/api/file/uploadManyFilesWithoutId`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags app-configs
     * @name AppConfigControllerCreate
     * @request POST:/api/app-configs
     * @secure
     */
    appConfigControllerCreate: (data: AppConfigCreateInput, params: RequestParams = {}) =>
      this.request<AppConfig, ForbiddenException>({
        path: `/api/app-configs`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags app-configs
     * @name AppConfigControllerFindMany
     * @request GET:/api/app-configs
     * @secure
     */
    appConfigControllerFindMany: (
      query?: {
        where?: AppConfigWhereInput;
        orderBy?: AppConfigOrderByInput;
        skip?: number;
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetListAppConfigDto, void>({
        path: `/api/app-configs`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags app-configs
     * @name AppConfigControllerFindOne
     * @request GET:/api/app-configs/{id}
     * @secure
     */
    appConfigControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<AppConfig, ForbiddenException | NotFoundException>({
        path: `/api/app-configs/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags app-configs
     * @name AppConfigControllerUpdate
     * @request PATCH:/api/app-configs/{id}
     * @secure
     */
    appConfigControllerUpdate: (id: string, data: AppConfigUpdateInput, params: RequestParams = {}) =>
      this.request<AppConfig, ForbiddenException | NotFoundException>({
        path: `/api/app-configs/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags app-configs
     * @name AppConfigControllerDelete
     * @request DELETE:/api/app-configs/{id}
     * @secure
     */
    appConfigControllerDelete: (id: string, params: RequestParams = {}) =>
      this.request<AppConfig, ForbiddenException | NotFoundException>({
        path: `/api/app-configs/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags notifications
     * @name NotificationControllerCreate
     * @request POST:/api/notifications
     * @secure
     */
    notificationControllerCreate: (data: NotificationCreateInput, params: RequestParams = {}) =>
      this.request<Notification, ForbiddenException>({
        path: `/api/notifications`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags notifications
     * @name NotificationControllerFindMany
     * @request GET:/api/notifications
     * @secure
     */
    notificationControllerFindMany: (
      query?: {
        where?: NotificationWhereInput;
        orderBy?: NotificationOrderByInput;
        skip?: number;
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetListNotificationDto, void>({
        path: `/api/notifications`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags notifications
     * @name NotificationControllerCreateMany
     * @request POST:/api/notifications/createMany
     * @secure
     */
    notificationControllerCreateMany: (data: string[], params: RequestParams = {}) =>
      this.request<Notification, ForbiddenException>({
        path: `/api/notifications/createMany`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags notifications
     * @name NotificationControllerFindOne
     * @request GET:/api/notifications/{id}
     * @secure
     */
    notificationControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<Notification, ForbiddenException | NotFoundException>({
        path: `/api/notifications/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags notifications
     * @name NotificationControllerUpdate
     * @request PATCH:/api/notifications/{id}
     * @secure
     */
    notificationControllerUpdate: (id: string, data: NotificationUpdateInput, params: RequestParams = {}) =>
      this.request<Notification, ForbiddenException | NotFoundException>({
        path: `/api/notifications/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags notifications
     * @name NotificationControllerDelete
     * @request DELETE:/api/notifications/{id}
     * @secure
     */
    notificationControllerDelete: (id: string, params: RequestParams = {}) =>
      this.request<Notification, ForbiddenException | NotFoundException>({
        path: `/api/notifications/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspaces
     * @name WorkspaceControllerCreate
     * @request POST:/api/workspaces
     * @secure
     */
    workspaceControllerCreate: (data: WorkspaceCreateWithVersionDto, params: RequestParams = {}) =>
      this.request<Workspace, ForbiddenException>({
        path: `/api/workspaces`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspaces
     * @name WorkspaceControllerFindMany
     * @request GET:/api/workspaces
     * @secure
     */
    workspaceControllerFindMany: (
      query?: {
        where?: WorkspaceWhereInput;
        orderBy?: WorkspaceOrderByInput;
        skip?: number;
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetListWorkspaceDto, void>({
        path: `/api/workspaces`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspaces
     * @name WorkspaceControllerFindOne
     * @request GET:/api/workspaces/{id}
     * @secure
     */
    workspaceControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<Workspace, ForbiddenException | NotFoundException>({
        path: `/api/workspaces/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspaces
     * @name WorkspaceControllerUpdate
     * @request PATCH:/api/workspaces/{id}
     * @secure
     */
    workspaceControllerUpdate: (id: string, data: UpdateWorkspaceInfoDto, params: RequestParams = {}) =>
      this.request<Workspace, ForbiddenException | NotFoundException>({
        path: `/api/workspaces/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspaces
     * @name WorkspaceControllerDelete
     * @request DELETE:/api/workspaces/{id}
     * @secure
     */
    workspaceControllerDelete: (id: string, params: RequestParams = {}) =>
      this.request<Workspace, ForbiddenException | NotFoundException>({
        path: `/api/workspaces/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspaces
     * @name WorkspaceControllerFindManyWorkspaceSteps
     * @request GET:/api/workspaces/{id}/workspaceSteps
     * @secure
     */
    workspaceControllerFindManyWorkspaceSteps: (
      id: string,
      query?: {
        workspace?: WorkspaceWhereUniqueInput;
        id?: StringFilter;
        type?: "Prospect" | "File";
        name?: StringFilter;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/workspaces/${id}/workspaceSteps`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspaces
     * @name WorkspaceControllerCreateWorkspaceSteps
     * @request POST:/api/workspaces/{id}/workspaceSteps
     * @secure
     */
    workspaceControllerCreateWorkspaceSteps: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/workspaces/${id}/workspaceSteps`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspaces
     * @name WorkspaceControllerUpdateWorkspaceSteps
     * @request PATCH:/api/workspaces/{id}/workspaceSteps
     * @secure
     */
    workspaceControllerUpdateWorkspaceSteps: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/workspaces/${id}/workspaceSteps`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspaces
     * @name WorkspaceControllerDeleteWorkspaceSteps
     * @request DELETE:/api/workspaces/{id}/workspaceSteps
     * @secure
     */
    workspaceControllerDeleteWorkspaceSteps: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/workspaces/${id}/workspaceSteps`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspaces
     * @name WorkspaceControllerFindManyWorkspaceGroups
     * @request GET:/api/workspaces/{id}/workspaceGroups
     * @secure
     */
    workspaceControllerFindManyWorkspaceGroups: (
      id: string,
      query?: {
        id?: StringFilter;
        workspace?: WorkspaceWhereUniqueInput;
        name?: StringFilter;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/workspaces/${id}/workspaceGroups`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspaces
     * @name WorkspaceControllerCreateWorkspaceGroups
     * @request POST:/api/workspaces/{id}/workspaceGroups
     * @secure
     */
    workspaceControllerCreateWorkspaceGroups: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/workspaces/${id}/workspaceGroups`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspaces
     * @name WorkspaceControllerUpdateWorkspaceGroups
     * @request PATCH:/api/workspaces/{id}/workspaceGroups
     * @secure
     */
    workspaceControllerUpdateWorkspaceGroups: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/workspaces/${id}/workspaceGroups`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspaces
     * @name WorkspaceControllerDeleteWorkspaceGroups
     * @request DELETE:/api/workspaces/{id}/workspaceGroups
     * @secure
     */
    workspaceControllerDeleteWorkspaceGroups: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/workspaces/${id}/workspaceGroups`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspaces
     * @name WorkspaceControllerFindManyClients
     * @request GET:/api/workspaces/{id}/clients
     * @secure
     */
    workspaceControllerFindManyClients: (
      id: string,
      query?: {
        id?: StringFilter;
        isBanned?: BooleanNullableFilter;
        firstName?: StringNullableFilter;
        lastName?: StringNullableFilter;
        email?: StringFilter;
        workspace?: WorkspaceWhereUniqueInput;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/workspaces/${id}/clients`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspaces
     * @name WorkspaceControllerCreateClients
     * @request POST:/api/workspaces/{id}/clients
     * @secure
     */
    workspaceControllerCreateClients: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/workspaces/${id}/clients`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspaces
     * @name WorkspaceControllerUpdateClients
     * @request PATCH:/api/workspaces/{id}/clients
     * @secure
     */
    workspaceControllerUpdateClients: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/workspaces/${id}/clients`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspaces
     * @name WorkspaceControllerDeleteClients
     * @request DELETE:/api/workspaces/{id}/clients
     * @secure
     */
    workspaceControllerDeleteClients: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/workspaces/${id}/clients`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspaces
     * @name WorkspaceControllerFindManyWorkspaceVersions
     * @request GET:/api/workspaces/{id}/workspaceVersions
     * @secure
     */
    workspaceControllerFindManyWorkspaceVersions: (
      id: string,
      query?: {
        id?: StringFilter;
        active?: BooleanNullableFilter;
        data?: JsonFilter;
        name?: StringFilter;
        workspace?: WorkspaceWhereUniqueInput;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/workspaces/${id}/workspaceVersions`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspaces
     * @name WorkspaceControllerCreateWorkspaceVersions
     * @request POST:/api/workspaces/{id}/workspaceVersions
     * @secure
     */
    workspaceControllerCreateWorkspaceVersions: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/workspaces/${id}/workspaceVersions`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspaces
     * @name WorkspaceControllerUpdateWorkspaceVersions
     * @request PATCH:/api/workspaces/{id}/workspaceVersions
     * @secure
     */
    workspaceControllerUpdateWorkspaceVersions: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/workspaces/${id}/workspaceVersions`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspaces
     * @name WorkspaceControllerDeleteWorkspaceVersions
     * @request DELETE:/api/workspaces/{id}/workspaceVersions
     * @secure
     */
    workspaceControllerDeleteWorkspaceVersions: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/workspaces/${id}/workspaceVersions`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags clients
     * @name ClientControllerCreate
     * @request POST:/api/clients
     * @secure
     */
    clientControllerCreate: (data: ClientCreateInput, params: RequestParams = {}) =>
      this.request<Client, ForbiddenException>({
        path: `/api/clients`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags clients
     * @name ClientControllerFindMany
     * @request GET:/api/clients
     * @secure
     */
    clientControllerFindMany: (
      query?: {
        where?: ClientWhereInput;
        orderBy?: ClientOrderByInput;
        skip?: number;
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetListClientDto, void>({
        path: `/api/clients`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags clients
     * @name ClientControllerFindOrInsert
     * @request GET:/api/clients/{idWorkspace}
     * @secure
     */
    clientControllerFindOrInsert: (idWorkspace: string, params: RequestParams = {}) =>
      this.request<any, ForbiddenException | NotFoundException>({
        path: `/api/clients/${idWorkspace}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags clients
     * @name ClientControllerUpdate
     * @request PATCH:/api/clients/{id}
     * @secure
     */
    clientControllerUpdate: (id: string, data: ClientUpdateInput, params: RequestParams = {}) =>
      this.request<Client, ForbiddenException | NotFoundException>({
        path: `/api/clients/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags clients
     * @name ClientControllerDelete
     * @request DELETE:/api/clients/{id}
     * @secure
     */
    clientControllerDelete: (id: string, params: RequestParams = {}) =>
      this.request<Client, ForbiddenException | NotFoundException>({
        path: `/api/clients/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags clients
     * @name ClientControllerFindOne
     * @request GET:/api/clients/{id}
     * @secure
     */
    clientControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<Client, ForbiddenException | NotFoundException>({
        path: `/api/clients/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags clients
     * @name ClientControllerFindManyWorkspaceResponsesOnClients
     * @request GET:/api/clients/{id}/workspaceResponsesOnClients
     * @secure
     */
    clientControllerFindManyWorkspaceResponsesOnClients: (
      id: string,
      query?: {
        id?: StringFilter;
        client?: ClientWhereUniqueInput;
        workspaceResponse?: WorkspaceResponseWhereUniqueInput;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/clients/${id}/workspaceResponsesOnClients`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags clients
     * @name ClientControllerCreateWorkspaceResponsesOnClients
     * @request POST:/api/clients/{id}/workspaceResponsesOnClients
     * @secure
     */
    clientControllerCreateWorkspaceResponsesOnClients: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/clients/${id}/workspaceResponsesOnClients`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags clients
     * @name ClientControllerUpdateWorkspaceResponsesOnClients
     * @request PATCH:/api/clients/{id}/workspaceResponsesOnClients
     * @secure
     */
    clientControllerUpdateWorkspaceResponsesOnClients: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/clients/${id}/workspaceResponsesOnClients`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags clients
     * @name ClientControllerDeleteWorkspaceResponsesOnClients
     * @request DELETE:/api/clients/{id}/workspaceResponsesOnClients
     * @secure
     */
    clientControllerDeleteWorkspaceResponsesOnClients: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/clients/${id}/workspaceResponsesOnClients`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags clients
     * @name ClientControllerUpdatePassword
     * @request PATCH:/api/clients/{id}/resetPassword
     * @secure
     */
    clientControllerUpdatePassword: (id: string, data: UpdatePasswordDTO, params: RequestParams = {}) =>
      this.request<Client, ForbiddenException | NotFoundException>({
        path: `/api/clients/${id}/resetPassword`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-steps
     * @name WorkspaceStepControllerCreate
     * @request POST:/api/workspace-steps
     * @secure
     */
    workspaceStepControllerCreate: (data: WorkspaceStepCreateInput, params: RequestParams = {}) =>
      this.request<WorkspaceStep, ForbiddenException>({
        path: `/api/workspace-steps`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-steps
     * @name WorkspaceStepControllerFindMany
     * @request POST:/api/workspace-steps/getManyWorkspaceSteps
     * @secure
     */
    workspaceStepControllerFindMany: (
      data: FetchStepDto,
      query?: {
        where?: WorkspaceStepWhereInput;
        orderBy?: WorkspaceStepOrderByInput;
        skip?: number;
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetListWorkspaceStepDto, void>({
        path: `/api/workspace-steps/getManyWorkspaceSteps`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-steps
     * @name WorkspaceStepControllerFindOne
     * @request GET:/api/workspace-steps/{id}
     * @secure
     */
    workspaceStepControllerFindOne: (id: string, type?: "Prospect" | "File", params: RequestParams = {}) =>
      this.request<WorkspaceStep, ForbiddenException | NotFoundException>({
        path: `/api/workspace-steps/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-steps
     * @name WorkspaceStepControllerUpdate
     * @request PATCH:/api/workspace-steps/{id}
     * @secure
     */
    workspaceStepControllerUpdate: (
      id: string,
      data: WorkspaceStepUpdateInput,
      type?: "Prospect" | "File",
      params: RequestParams = {},
    ) =>
      this.request<WorkspaceStep, ForbiddenException | NotFoundException>({
        path: `/api/workspace-steps/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-steps
     * @name WorkspaceStepControllerDelete
     * @request DELETE:/api/workspace-steps/{id}
     * @secure
     */
    workspaceStepControllerDelete: (id: string, type?: "Prospect" | "File", params: RequestParams = {}) =>
      this.request<WorkspaceStep, ForbiddenException | NotFoundException>({
        path: `/api/workspace-steps/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-steps
     * @name WorkspaceStepControllerFindManyWorkspaceResponses
     * @request GET:/api/workspace-steps/{id}/workspaceResponses
     * @secure
     */
    workspaceStepControllerFindManyWorkspaceResponses: (
      id: string,
      type?: "Prospect" | "File",
      query?: {
        signingStatus?: "Step0" | "Step1" | "Step2" | "Step3";
        workspaceVersion?: WorkspaceVersionWhereUniqueInput;
        id?: StringFilter;
        workspaceStep?: WorkspaceStepWhereUniqueInput;
        workspace?: WorkspaceWhereUniqueInput;
        data?: JsonFilter;
        type?: "Prospect" | "File";
        workspaceResponsesOnClients?: WorkspaceResponsesOnClientListRelationFilter;
        isDeleted?: boolean;
        isNotified?: boolean;
        isSignedByAdmin?: boolean;
        workspaceGroup?: WorkspaceGroupWhereUniqueInput;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/workspace-steps/${id}/workspaceResponses`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-steps
     * @name WorkspaceStepControllerCreateWorkspaceResponses
     * @request POST:/api/workspace-steps/{id}/workspaceResponses
     * @secure
     */
    workspaceStepControllerCreateWorkspaceResponses: (
      id: string,
      data: string[],
      type?: "Prospect" | "File",
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/workspace-steps/${id}/workspaceResponses`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-steps
     * @name WorkspaceStepControllerUpdateWorkspaceResponses
     * @request PATCH:/api/workspace-steps/{id}/workspaceResponses
     * @secure
     */
    workspaceStepControllerUpdateWorkspaceResponses: (
      id: string,
      data: string[],
      type?: "Prospect" | "File",
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/workspace-steps/${id}/workspaceResponses`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-steps
     * @name WorkspaceStepControllerDeleteWorkspaceResponses
     * @request DELETE:/api/workspace-steps/{id}/workspaceResponses
     * @secure
     */
    workspaceStepControllerDeleteWorkspaceResponses: (
      id: string,
      data: string[],
      type?: "Prospect" | "File",
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/workspace-steps/${id}/workspaceResponses`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-versions
     * @name WorkspaceVersionControllerCreate
     * @request POST:/api/workspace-versions
     * @secure
     */
    workspaceVersionControllerCreate: (data: WorkspaceVersionCreateInput, params: RequestParams = {}) =>
      this.request<WorkspaceVersion, ForbiddenException>({
        path: `/api/workspace-versions`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-versions
     * @name WorkspaceVersionControllerFindMany
     * @request GET:/api/workspace-versions
     * @secure
     */
    workspaceVersionControllerFindMany: (
      query?: {
        where?: WorkspaceVersionWhereInput;
        orderBy?: WorkspaceVersionOrderByInput;
        skip?: number;
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetListWorkspaceVersionDto, void>({
        path: `/api/workspace-versions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-versions
     * @name WorkspaceVersionControllerFindOne
     * @request GET:/api/workspace-versions/{id}
     * @secure
     */
    workspaceVersionControllerFindOne: (
      id: string,
      query: {
        id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<WorkspaceVersion, ForbiddenException | NotFoundException>({
        path: `/api/workspace-versions/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-versions
     * @name WorkspaceVersionControllerUpdate
     * @request PATCH:/api/workspace-versions/{id}
     * @secure
     */
    workspaceVersionControllerUpdate: (
      id: string,
      query: {
        id: string;
      },
      data: WorkspaceVersionUpdateInput,
      params: RequestParams = {},
    ) =>
      this.request<WorkspaceVersion, ForbiddenException | NotFoundException>({
        path: `/api/workspace-versions/${id}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-versions
     * @name WorkspaceVersionControllerDelete
     * @request DELETE:/api/workspace-versions/{id}
     * @secure
     */
    workspaceVersionControllerDelete: (
      id: string,
      query: {
        id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<WorkspaceVersion, ForbiddenException | NotFoundException>({
        path: `/api/workspace-versions/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-versions
     * @name WorkspaceVersionControllerFindActiveByIdWorkspace
     * @request GET:/api/workspace-versions/findFirstActiveVersion/{id}
     * @secure
     */
    workspaceVersionControllerFindActiveByIdWorkspace: (id: string, params: RequestParams = {}) =>
      this.request<WorkspaceVersion, ForbiddenException | NotFoundException>({
        path: `/api/workspace-versions/findFirstActiveVersion/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-versions
     * @name WorkspaceVersionControllerFindManyWorkspaceResponses
     * @request GET:/api/workspace-versions/{id}/workspaceResponses
     * @secure
     */
    workspaceVersionControllerFindManyWorkspaceResponses: (
      id: string,
      query?: {
        id?: StringFilter;
        signingStatus?: "Step0" | "Step1" | "Step2" | "Step3";
        workspaceVersion?: WorkspaceVersionWhereUniqueInput;
        workspaceStep?: WorkspaceStepWhereUniqueInput;
        workspace?: WorkspaceWhereUniqueInput;
        data?: JsonFilter;
        type?: "Prospect" | "File";
        workspaceResponsesOnClients?: WorkspaceResponsesOnClientListRelationFilter;
        isDeleted?: boolean;
        isNotified?: boolean;
        isSignedByAdmin?: boolean;
        workspaceGroup?: WorkspaceGroupWhereUniqueInput;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/workspace-versions/${id}/workspaceResponses`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-versions
     * @name WorkspaceVersionControllerCreateWorkspaceResponses
     * @request POST:/api/workspace-versions/{id}/workspaceResponses
     * @secure
     */
    workspaceVersionControllerCreateWorkspaceResponses: (
      id: string,
      query: {
        id: string;
      },
      data: string[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/workspace-versions/${id}/workspaceResponses`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-versions
     * @name WorkspaceVersionControllerUpdateWorkspaceResponses
     * @request PATCH:/api/workspace-versions/{id}/workspaceResponses
     * @secure
     */
    workspaceVersionControllerUpdateWorkspaceResponses: (
      id: string,
      query: {
        id: string;
      },
      data: string[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/workspace-versions/${id}/workspaceResponses`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-versions
     * @name WorkspaceVersionControllerDeleteWorkspaceResponses
     * @request DELETE:/api/workspace-versions/{id}/workspaceResponses
     * @secure
     */
    workspaceVersionControllerDeleteWorkspaceResponses: (
      id: string,
      query: {
        id: string;
      },
      data: string[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/workspace-versions/${id}/workspaceResponses`,
        method: "DELETE",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-versions
     * @name WorkspaceVersionControllerExportExcelWorkspaceResponses
     * @request POST:/api/workspace-versions/exportExcelWorkspaceResponses
     * @secure
     */
    workspaceVersionControllerExportExcelWorkspaceResponses: (
      data: ExportExcelWorkspaceResponseInput,
      params: RequestParams = {},
    ) =>
      this.request<WorkspaceVersion, ForbiddenException | NotFoundException>({
        path: `/api/workspace-versions/exportExcelWorkspaceResponses`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-versions
     * @name WorkspaceVersionControllerExportExcelWorkspaceQuery
     * @request POST:/api/workspace-versions/exportWorkspaceQuery
     * @secure
     */
    workspaceVersionControllerExportExcelWorkspaceQuery: (
      data: ExportExcelWorkspaceResponseInput,
      params: RequestParams = {},
    ) =>
      this.request<WorkspaceVersion, ForbiddenException | NotFoundException>({
        path: `/api/workspace-versions/exportWorkspaceQuery`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-versions
     * @name WorkspaceVersionControllerImportWorkspaceResponses
     * @request POST:/api/workspace-versions/importWorkspaceResponses
     * @secure
     */
    workspaceVersionControllerImportWorkspaceResponses: (
      data: ImportExcelWorkspaceResponseInput,
      params: RequestParams = {},
    ) =>
      this.request<WorkspaceVersion, ForbiddenException | NotFoundException>({
        path: `/api/workspace-versions/importWorkspaceResponses`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-versions
     * @name WorkspaceVersionControllerInviteAllUserstoSignup
     * @request POST:/api/workspace-versions/inviteAllUserstoSignup
     * @secure
     */
    workspaceVersionControllerInviteAllUserstoSignup: (data: InvitAllClient, params: RequestParams = {}) =>
      this.request<WorkspaceVersion, ForbiddenException | NotFoundException>({
        path: `/api/workspace-versions/inviteAllUserstoSignup`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-groups
     * @name WorkspaceGroupControllerGetGroupsByWorkspace
     * @request GET:/api/workspace-groups/getGroupsByWorkspace
     * @secure
     */
    workspaceGroupControllerGetGroupsByWorkspace: (
      id?: string,
      query?: {
        where?: WorkspaceGroupWhereInput;
        orderBy?: WorkspaceGroupOrderByInput;
        skip?: number;
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetListWorkspaceGroupDto, void>({
        path: `/api/workspace-groups/getGroupsByWorkspace`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-groups
     * @name WorkspaceGroupControllerCreate
     * @request POST:/api/workspace-groups
     * @secure
     */
    workspaceGroupControllerCreate: (data: WorkspaceGroupCreateInput, params: RequestParams = {}) =>
      this.request<WorkspaceGroup, ForbiddenException>({
        path: `/api/workspace-groups`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-groups
     * @name WorkspaceGroupControllerFindMany
     * @request GET:/api/workspace-groups
     * @secure
     */
    workspaceGroupControllerFindMany: (
      query?: {
        where?: WorkspaceGroupWhereInput;
        orderBy?: WorkspaceGroupOrderByInput;
        skip?: number;
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetListWorkspaceGroupDto, void>({
        path: `/api/workspace-groups`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-groups
     * @name WorkspaceGroupControllerFindOne
     * @request GET:/api/workspace-groups/{id}
     * @secure
     */
    workspaceGroupControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<WorkspaceGroup, ForbiddenException | NotFoundException>({
        path: `/api/workspace-groups/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-groups
     * @name WorkspaceGroupControllerUpdate
     * @request PATCH:/api/workspace-groups/{id}
     * @secure
     */
    workspaceGroupControllerUpdate: (id: string, data: WorkspaceGroupUpdateInput, params: RequestParams = {}) =>
      this.request<WorkspaceGroup, ForbiddenException | NotFoundException>({
        path: `/api/workspace-groups/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-groups
     * @name WorkspaceGroupControllerDelete
     * @request DELETE:/api/workspace-groups/{id}
     * @secure
     */
    workspaceGroupControllerDelete: (id: string, params: RequestParams = {}) =>
      this.request<WorkspaceGroup, ForbiddenException | NotFoundException>({
        path: `/api/workspace-groups/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags activities
     * @name ActivityControllerCreate
     * @request POST:/api/activities
     * @secure
     */
    activityControllerCreate: (data: ActivityCreateInput, params: RequestParams = {}) =>
      this.request<Activity, ForbiddenException>({
        path: `/api/activities`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags activities
     * @name ActivityControllerFindMany
     * @request GET:/api/activities
     * @secure
     */
    activityControllerFindMany: (
      query?: {
        where?: ActivityWhereInput;
        orderBy?: ActivityOrderByInput;
        skip?: number;
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetListActivityDto, void>({
        path: `/api/activities`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags activities
     * @name ActivityControllerFindOne
     * @request GET:/api/activities/{id}
     * @secure
     */
    activityControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<Activity, ForbiddenException | NotFoundException>({
        path: `/api/activities/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags activities
     * @name ActivityControllerUpdate
     * @request PATCH:/api/activities/{id}
     * @secure
     */
    activityControllerUpdate: (id: string, data: ActivityUpdateInput, params: RequestParams = {}) =>
      this.request<Activity, ForbiddenException | NotFoundException>({
        path: `/api/activities/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags activities
     * @name ActivityControllerDelete
     * @request DELETE:/api/activities/{id}
     * @secure
     */
    activityControllerDelete: (id: string, params: RequestParams = {}) =>
      this.request<Activity, ForbiddenException | NotFoundException>({
        path: `/api/activities/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags activities
     * @name ActivityControllerFindActivityByWorkspaceResponseId
     * @request GET:/api/activities/findActivityByWorkspaceResponseId/{id}
     * @secure
     */
    activityControllerFindActivityByWorkspaceResponseId: (
      id: string,
      query: {
        id: string;
        workspace?: WorkspaceWhereUniqueInput;
        equals?: any;
        in?: string[];
        notIn?: string[];
        lt?: string;
        lte?: string;
        gt?: string;
        gte?: string;
        contains?: string;
        startsWith?: string;
        endsWith?: string;
        mode?: "Default" | "Insensitive";
        not?: any;
        type?: "Prospect" | "File";
        none?: WorkspaceResponsesOnClientWhereInputMailDto;
        some?: WorkspaceResponsesOnClientWhereInputMailDto;
        every?: WorkspaceResponsesOnClientWhereInputMailDto;
        where?: ActivityWhereInput;
        orderBy?: ActivityOrderByInput;
        skip?: number;
        take?: number;
      },
      signingStatus?: "Step0" | "Step1" | "Step2" | "Step3",
      type?: "Prospect" | "File",
      isDeleted?: boolean,
      isNotified?: boolean,
      isSignedByAdmin?: boolean,
      params: RequestParams = {},
    ) =>
      this.request<GetListActivityDto, void>({
        path: `/api/activities/findActivityByWorkspaceResponseId/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses-on-clients
     * @name WorkspaceResponsesOnClientControllerGetWorkspaceResponsesClient
     * @request POST:/api/workspace-responses-on-clients/getWorkspaceResponsesClient
     * @secure
     */
    workspaceResponsesOnClientControllerGetWorkspaceResponsesClient: (
      data: WorkspaceResponsesOnClientWhereUniqueInput,
      params: RequestParams = {},
    ) =>
      this.request<WorkspaceResponsesOnClient, any>({
        path: `/api/workspace-responses-on-clients/getWorkspaceResponsesClient`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses-on-clients
     * @name WorkspaceResponsesOnClientControllerCreate
     * @request POST:/api/workspace-responses-on-clients
     * @secure
     */
    workspaceResponsesOnClientControllerCreate: (
      data: WorkspaceResponsesOnClientCreateInput,
      params: RequestParams = {},
    ) =>
      this.request<WorkspaceResponsesOnClient, ForbiddenException>({
        path: `/api/workspace-responses-on-clients`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses-on-clients
     * @name WorkspaceResponsesOnClientControllerFindMany
     * @request GET:/api/workspace-responses-on-clients
     * @secure
     */
    workspaceResponsesOnClientControllerFindMany: (
      query?: {
        where?: WorkspaceResponsesOnClientWhereInput;
        orderBy?: WorkspaceResponsesOnClientOrderByInput;
        skip?: number;
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetListWorkspaceResponsesOnClientDto, void>({
        path: `/api/workspace-responses-on-clients`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses-on-clients
     * @name WorkspaceResponsesOnClientControllerFindOne
     * @request GET:/api/workspace-responses-on-clients/{id}
     * @secure
     */
    workspaceResponsesOnClientControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<WorkspaceResponsesOnClient, ForbiddenException | NotFoundException>({
        path: `/api/workspace-responses-on-clients/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses-on-clients
     * @name WorkspaceResponsesOnClientControllerUpdate
     * @request PATCH:/api/workspace-responses-on-clients/{id}
     * @secure
     */
    workspaceResponsesOnClientControllerUpdate: (
      id: string,
      data: WorkspaceResponsesOnClientUpdateInput,
      params: RequestParams = {},
    ) =>
      this.request<WorkspaceResponsesOnClient, ForbiddenException | NotFoundException>({
        path: `/api/workspace-responses-on-clients/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace-responses-on-clients
     * @name WorkspaceResponsesOnClientControllerDelete
     * @request DELETE:/api/workspace-responses-on-clients/{id}
     * @secure
     */
    workspaceResponsesOnClientControllerDelete: (id: string, params: RequestParams = {}) =>
      this.request<WorkspaceResponsesOnClient, ForbiddenException | NotFoundException>({
        path: `/api/workspace-responses-on-clients/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name HealthControllerHealthLive
     * @request GET:/api/_health/live
     */
    healthControllerHealthLive: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/_health/live`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @name HealthControllerHealthReady
     * @request GET:/api/_health/ready
     */
    healthControllerHealthReady: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/_health/ready`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags generateDoc
     * @name GenerateContratControllerGenerateContrat
     * @request GET:/api/generateDoc/generateContrat/{workspaceResponseId}/{number}
     * @secure
     */
    generateContratControllerGenerateContrat: (
      workspaceResponseId: string,
      number: string,
      params: RequestParams = {},
    ) =>
      this.request<StreamableFile, void>({
        path: `/api/generateDoc/generateContrat/${workspaceResponseId}/${number}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags generateDoc
     * @name GenerateContratControllerGetContracts
     * @request GET:/api/generateDoc/getContracts/{workspaceResponseId}
     * @secure
     */
    generateContratControllerGetContracts: (workspaceResponseId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/api/generateDoc/getContracts/${workspaceResponseId}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name GoogleSpreadSheetControllerAddTemplate
     * @request POST:/api/googleSpreadSheet/template/create
     */
    googleSpreadSheetControllerAddTemplate: (data: TemplateCreateInput, params: RequestParams = {}) =>
      this.request<TemplateCreateInput, any>({
        path: `/api/googleSpreadSheet/template/create`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GoogleSpreadSheetControllerDuplicated
     * @request POST:/api/googleSpreadSheet/template/duplicated
     */
    googleSpreadSheetControllerDuplicated: (data: TemplateWhereUniqueInputId, params: RequestParams = {}) =>
      this.request<TemplateCreateInput, any>({
        path: `/api/googleSpreadSheet/template/duplicated`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GoogleSpreadSheetControllerFindMany
     * @request POST:/api/googleSpreadSheet/template/findMany
     */
    googleSpreadSheetControllerFindMany: (data: PaginationTemplateDto, params: RequestParams = {}) =>
      this.request<TemplateCreateInput, any>({
        path: `/api/googleSpreadSheet/template/findMany`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GoogleSpreadSheetControllerFindManyByTitle
     * @request POST:/api/googleSpreadSheet/template/findManyByTitle
     */
    googleSpreadSheetControllerFindManyByTitle: (data: FilterByTitle, params: RequestParams = {}) =>
      this.request<TemplateCreateInput, any>({
        path: `/api/googleSpreadSheet/template/findManyByTitle`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GoogleSpreadSheetControllerDeleteTemplateByName
     * @request POST:/api/googleSpreadSheet/deleteTemplateByName
     */
    googleSpreadSheetControllerDeleteTemplateByName: (
      data: TemplateWhereUniqueInputTemplateName,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/googleSpreadSheet/deleteTemplateByName`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GoogleSpreadSheetControllerFindTemplateById
     * @request POST:/api/googleSpreadSheet/findTemplateById
     */
    googleSpreadSheetControllerFindTemplateById: (data: TemplateWhereUniqueInputId, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/googleSpreadSheet/findTemplateById`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GoogleSpreadSheetControllerUpdateTemplate
     * @request POST:/api/googleSpreadSheet/{templateName}/template/update
     */
    googleSpreadSheetControllerUpdateTemplate: (
      templateName: string,
      data: UpdateTemplateInput,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/googleSpreadSheet/${templateName}/template/update`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GoogleSpreadSheetControllerLinkClicked
     * @request POST:/api/googleSpreadSheet/email_sent
     */
    googleSpreadSheetControllerLinkClicked: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/googleSpreadSheet/email_sent`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @name GoogleSpreadSheetControllerEmailTrack
     * @request POST:/api/googleSpreadSheet/email_track
     */
    googleSpreadSheetControllerEmailTrack: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/googleSpreadSheet/email_track`,
        method: "POST",
        ...params,
      }),
  };
}
